import * as React from 'react'
import styled from 'styled-components'

import { changeNavColor } from '../styles/utils'

import NewsletterRegistration from './footer/NewsletterRegistration'

import Link from './common/Link'
import {
  Container,
  LinkedinIcon,
  FacebookIcon,
  TwitterIcon,
  MediumIcon,
  MeetupIcon,
  Heading,
  HeadingLevel,
  HeadingSize,
  Row,
  Col,
  media,
  UnderlinedButton,
  Text,
  colors,
  gridLgSpacing,
  themes,
} from 'cc-ui-components'

import Waypoint from '@clayne/react-waypoint'
import LanguageSwitch from './footer/LanguageSwitch'
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl'
import { graphql, StaticQuery } from 'gatsby'
import * as _ from 'lodash'

interface FooterProps extends InjectedIntlProps {
  onNewsletterSubmit?: () => void
}

interface FooterState {
  activeIndex: number
}

interface ColFooterProps {
  active?: boolean
}

const Wrapper = styled.footer`
  background: ${colors.darkBackground};
  color: ${colors.inverseText};
  z-index: 8;
  position: relative;
  ${media.tablet`
    & > div > div > div:first-of-type {
      padding-bottom: 40px;
    }
  `};
`

const FooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  a {
    span {
      color: ${colors.inverseText};
    }
  }
  ${media.tablet`
    flex-direction: column;
    & > div {
      width: 100%;
    }
    h6 {
      font-size: 1rem;
    }
    nav {
      margin-bottom: 16px;
    }
  `};
`

const ColFooter = styled.div<ColFooterProps>`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  h6 {
    display: flex;
    justify-content: space-between;
    user-select: none;
    svg {
      display: none;
    }
  }
  ${media.tablet`
    span, h6 {
      text-transform: unset;
    }
    h6 {
      margin-bottom: 8px;
      line-height: 24px;
    }
  `};
`

const FirstCol = styled(ColFooter)`
  width: 20%;
  a > div {
    border-color: ${colors.inverseText};
    span {
      border-color: ${colors.inverseText};
    }
  }
  p {
    width: 165px;
  }
  ${media.tablet`
    margin-bottom: 53px;
    p {
      margin-bottom: 40px;
      padding-bottom: 0;
      width: 100%;
    }
    & > h5:first-of-type {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 16px;
      margin-top: 0;
    }
    a div {
      width: 100%;
    }
  `};
`

const LastCol = styled(ColFooter)`
  width: 20%;
  ${media.tablet`
    margin-top: 48px;
    nav {
      ${(props: ColFooterProps) => props.active && 'overflow: visible'};
    }
  `};
`

interface TextDescriptionProps {
  smallPaddingMobile?: boolean
}

const TextDescription = styled(Text)<TextDescriptionProps>`
  flex-grow: 1;
  ${media.tablet`
    padding-right: 1em;
    padding-bottom: ${(props: TextDescriptionProps) =>
      props.smallPaddingMobile ? 16 : 30}px;
  `};
`

const SocialIcon = styled.span`
  display: inline-block;
  width: 12px;
  margin-right: 7px;
  position: relative;
  top: 2px;

  svg {
    height: 12px;
    width: auto;

    &,
    path {
      transition: 170ms ease;
    }
  }

  ${media.tablet`
    display: none;
  `};
`

const Nav = styled.nav`
  flex: 1;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    user-select: none;
  }

  li {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    &:not(:hover) svg {
      &,
      path {
        fill: ${colors.lightBackground};
      }
    }
  }
  ${media.tablet`
    li {
      margin-top: 8px;
    }
  `};
`

const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  ${media.tablet`
    flex-wrap: wrap;
    & > div {
    width: 50% !important;
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 2;
      }
      &:nth-child(3) {
        order: 4;
      }
      &:nth-child(4) {
        order: 3;
      }
    }
  `};
`

interface HeadingOptionsProps {
  level: HeadingLevel
  size: HeadingSize
  trailer: number
}

const headingOptions: HeadingOptionsProps = {
  level: '6',
  size: 'xs',
  trailer: 4.27,
}

class Footer extends React.PureComponent<FooterProps, FooterState> {
  constructor(props: FooterProps) {
    super(props)
    this.state = {
      activeIndex: -1,
    }
  }

  public manageDropdown = (index: number) =>
    this.setState({
      activeIndex: this.state.activeIndex === index ? -1 : index,
    })

  public render() {
    const { onNewsletterSubmit, intl } = this.props
    const { activeIndex } = this.state

    return (
      <StaticQuery
        query={graphql`
          query {
            allContentfulModuleNavigation {
              edges {
                node {
                  title
                  node_locale
                  navigationElements {
                    ...navigationElements
                  }
                }
              }
            }
          }
        `}
        render={data => {
          let navItems = []
          const navigation: any =
            data.allContentfulModuleNavigation.edges.filter(
              (edge: any) =>
                edge.node.title === 'Footer Navigation' &&
                edge.node.node_locale.startsWith(intl.locale)
            )[0].node || null

          if (navigation) {
            navItems = navigation.navigationElements.map((element: any) => {
              const entries = element.subElements.map((subElement: any) => {
                const path =
                  _.get(subElement, 'targetPage.slug') || subElement.targetPath
                return {
                  title: subElement.title,
                  link: subElement.href || `/${intl.locale}/${path}`,
                  external: !!subElement.href,
                }
              })
              return { entries, title: element.title }
            })
          }

          return (
            <Wrapper>
              <Container padingsVertical={['40px', '60px']} theme={themes.dark}>
                <Row>
                  <Col center sm={12} md={12} lg={gridLgSpacing}>
                    <FooterInner>
                      <FirstCol>
                        <Heading level="5" size="md" trailer={4}>
                          CodeControl
                        </Heading>
                        <TextDescription size="xs" color={colors.inverseText}>
                          <FormattedMessage id="footer.about" />
                        </TextDescription>
                        <Link
                          to={`/${intl.locale}/${
                            intl.locale === 'de' ? 'ueber-uns' : 'about-us'
                          }`}
                        >
                          <FormattedMessage id="footer.cta">
                            {txt => (
                              <UnderlinedButton
                                color={colors.inverseText}
                                onClick={() => {}}
                              >
                                {txt}
                              </UnderlinedButton>
                            )}
                          </FormattedMessage>
                        </Link>
                      </FirstCol>
                      <MobileWrapper>
                        {navItems.map((item: any, index: number) => (
                          <ColFooter
                            active={activeIndex === index}
                            key={`col-${index}`}
                          >
                            <Heading
                              {...headingOptions}
                              onClick={this.manageDropdown.bind(this, index)}
                              uppercase
                            >
                              {item.title}
                            </Heading>
                            <Nav>
                              <ul>
                                {item.entries.map(
                                  (entry: any, entryIndex: number) => (
                                    <li key={`entry-${entryIndex}`}>
                                      {entry.external ? (
                                        <a
                                          href={entry.link}
                                          target={'_blank'}
                                          style={{ textDecoration: 'none' }}
                                        >
                                          <Text
                                            size="xs"
                                            tagName="span"
                                            uppercase
                                          >
                                            {entry.title}
                                          </Text>
                                        </a>
                                      ) : (
                                        <Link to={entry.link}>
                                          <Text
                                            size="xs"
                                            tagName="span"
                                            uppercase
                                          >
                                            {entry.title}
                                          </Text>
                                        </Link>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            </Nav>
                          </ColFooter>
                        ))}
                        <ColFooter active={activeIndex === 3}>
                          <Heading
                            {...headingOptions}
                            onClick={this.manageDropdown.bind(this, 3)}
                            uppercase
                          >
                            Follow us
                          </Heading>
                          <Nav>
                            <ul>
                              <li>
                                <a
                                  href="https://twitter.com/CodeControl_"
                                  target="_blank"
                                >
                                  <SocialIcon>
                                    <TwitterIcon />
                                  </SocialIcon>
                                  <Text size="xs" tagName="span" uppercase>
                                    Twitter
                                  </Text>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.facebook.com/codecontrol.io/"
                                  target="_blank"
                                >
                                  <SocialIcon>
                                    <FacebookIcon />
                                  </SocialIcon>
                                  <Text size="xs" tagName="span" uppercase>
                                    Facebook
                                  </Text>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.linkedin.com/company/codecontrol/"
                                  target="_blank"
                                >
                                  <SocialIcon>
                                    <LinkedinIcon />
                                  </SocialIcon>
                                  <Text size="xs" tagName="span" uppercase>
                                    LinkedIn
                                  </Text>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://medium.com/future-of-work"
                                  target="_blank"
                                >
                                  <SocialIcon>
                                    <MediumIcon />
                                  </SocialIcon>
                                  <Text size="xs" tagName="span" uppercase>
                                    Medium
                                  </Text>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.meetup.com/Future-of-Work-Berlin/"
                                  target="_blank"
                                >
                                  <SocialIcon>
                                    <MeetupIcon />
                                  </SocialIcon>
                                  <Text size="xs" tagName="span" uppercase>
                                    Meetup
                                  </Text>
                                </a>
                              </li>
                            </ul>
                          </Nav>
                        </ColFooter>
                      </MobileWrapper>
                      <LastCol active={activeIndex === 4}>
                        <Heading
                          {...headingOptions}
                          onClick={this.manageDropdown.bind(this, 4)}
                          uppercase
                        >
                          Language
                        </Heading>
                        <Nav>
                          <LanguageSwitch />
                        </Nav>
                        <FormattedMessage id="footer.newsletter_description">
                          {text => (
                            <TextDescription
                              leader={7.5}
                              size="xs"
                              color={colors.inverseText}
                              smallPaddingMobile
                            >
                              {text}
                            </TextDescription>
                          )}
                        </FormattedMessage>
                        <NewsletterRegistration onSubmit={onNewsletterSubmit} />
                      </LastCol>
                    </FooterInner>
                  </Col>
                </Row>
              </Container>
            </Wrapper>
          )
        }}
      />
    )
  }
}

const WrapperFooter: React.SFC<FooterProps> = ({
  onNewsletterSubmit,
  ...rest
}) => (
  <Waypoint
    onEnter={() => changeNavColor('black')}
    topOffset={'0'}
    bottomOffset={'99%'}
    scrollableAncestor={'window'}
  >
    <div>
      <Footer {...rest} onNewsletterSubmit={onNewsletterSubmit} />
    </div>
  </Waypoint>
)

export default injectIntl(WrapperFooter)
