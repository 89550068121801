module.exports = {
  'global.full_name': 'Name',
  'global.email': 'Business Email',
  'global.phone': 'Rufnummer',
  'global.privacy': 'Datenschutz',
  'global.imprint': 'Impressum',
  'global.contact': 'Kontakt',
  'global.get-in-contact': 'Kontaktiere uns',
  'global.terms': 'AGB',
  'path.contact': '/de/kontakt',
  'path.imprint': '/de/impressum',
  'path.privacy': '/de/datenschutz',
  'path.terms': '/de/agb',
  'path.thank-you': '/de/danke',
  'path.get-in-touch': '/de/kontaktiere-uns',
  'cookieConsent.buttonTitle': 'Cookies akzeptieren',
  'cookieConsent.disclaimer': 'Wir verwenden Cookies, um Deine Nutzererfahrung zu verbessern',
  selectLanguage: 'Wähle Deine Sprache',
  'language.de': 'German',
  'language.en': 'English',
  'language.fr': 'French',
  'header.cta': 'Jetzt anfragen',
  'footer.about':
    'CodeControl managed 750+ zuverlässige, freiberufliche Entwickler, welche erstklassige Software für für Kunden wie Lufthansa, BCG, and mymuesli erstellen.',
  'footer.cta': 'MEHR ERFAHREN',
  'footer.newsletter_description': 'Möchten Sie über unsere neuesten Nachrichten, Events und Blogs auf dem Laufenden bleiben?',
  'module.popular_searches.headline': 'Beliebte Entwickler-Suchen',
  'blog.index.headline': 'CodeControl Blog',
  'blog.index.subline': 'Ein Rückblick auf die Zukunft der Arbeit',
  'blog.index.mobile_more': 'Mehr laden...',
  'blog.index.teasersection.headline': 'Andere interessantes Artikel.',
  'blog.index.teasersection.subline': 'Bereit zum Durchlesen',
  'blog.index.medium_cta.headline': 'Erforsche the lates & greatest',
  'blog.index.medium_cta.subline': 'Future of Work Artikel',
  'blog.index.medium_cta.button': 'Mehr auf Medium',
  'blog.index.editor_section.subline': 'FOW Publikationen',
  'blog.index.editor_section.headline': 'Werde Future of Work Author auf Medium',
  'blog.index.editor_section.copy': 'Lorem Ipsum',
  'blog.index.editor_section.cta': 'Schreib uns',
  'blog.article.author_section.headline': 'Über den Autor',
  'blog.module.newsletter_section.headline': 'Interesse an fow updates?',
  'blog.module.newsletter_section.cta': 'Meld mich an!',
  'talent_cv.cta': 'Jetzt anfragen',
  'talent_cv.ct_available': 'Jetzt Einstellen',
  'talent_cv.ct_not_available': 'Jetzt Einstellen',
  'talent_cv.part_time_available_text': 'ist derzeit für eine Teilzeitanstellung verfügbar.',
  'talent_cv.full_time_available_text': 'ist derzeit für eine Vollzeitanstellung verfügbar.',
  'talent_cv.not_available_text': 'ist derzeit nicht verfügbar.',
  'talent_ct.work_experience.present': 'aktuell',
  'still.havent.title': 'NOCH NICHT GEFUNDEN, WAS DU SUCHST?',
  'still.havent.text': 'Dann sende uns bitte eine Nachricht. Gerne beantworten wir deine Fragen!',
  'still.havent.cta': 'SCHREIB UNS',
  'talent_cv.skill_section.headline': 'Hauptfähigkeiten',
  'talent_cv.skill_section.techskills': 'Technische Fähigkeiten',
  'talent_cv.skill_section.yearsofxp': 'Erfahrung in Jahren',
  'talent_cv.skill_section.proficiency': 'Fertigkeit',
  'talent_cv.project_section.headline': 'Arbeitserfahrung',
  'talent_cv.rating_section.headline': 'Bewertungen & Referenzen',
  'talent_cv.education_section.headline': 'Ausbildung & Zertifikate',
  'talent_cv.language_section.headline': 'Sprachen',
  'hire.title': 'Work | with CodeControl',
  'contact.title': 'Sprich | mit CodeControl',
  'contact.headline': 'Möchten Sie Kontakt aufnehmen? Tragen Sie Ihre Details unten ein.',
  'contact.calendly_section.headline': 'Ruf uns einfach an.',
  'contact.calendly_section.subline': 'Bitte wähle eine Zeit, die dir passt.',
  'contact.email_section.headline': 'Lieber per E-mail? Trage deine Daten unten ein.',
  'contact.email_section.CTA': 'ABSENDEN',
  'contact.email_section.footer':
    'Mit dem Absenden erklärst du dich mit unseren Allgemeinen Geschäftsbedingungen und unserer Datenschutzerklärung einverstanden.',
  'thank_you.headline': 'Danke!',
  'thank_you.intro_line1': 'Jemand von der CodeControl-Crew wird dir in Kürze eine E-Mail schicken.',
  'thank_you.intro_line2': 'Kann es nicht warten? Dann sende uns bitte eine E-Mail an: hello@codecontrol.io',
  'thank_you.intro_line3': 'Wenn du schon mal hier bist, lies doch einfach mal!',
  'thank_you.social_section.headline': 'Und folge unseren News & Events! ',
  'get-in-touch.requiredName': 'Bitte vollständigen Namen angeben',
  'get-in-touch.validEmail': 'Bitte eine gültige Email Adresse angeben',
  'get-in-touch.requiredEmail': 'Bitte Email Adresse angeben',
  'get-in-touch.requiredPhone': 'Bitte Telefonnummer angeben',
  'survey.ctaButton': 'Start',
  'survey.reset': 'Zurücksetzen',
  'survey.progress': 'Frage',
  'survey.previousButton': 'Zurück',
  'survey.contactInfo': 'Glückwunsch, wir haben passenden Talente für dich gefunden. Trage dich jetzt ein für ein unverbindliches Angebot.',
  'survey.placeholderName': 'Name',
  'survey.placeholderEmail': 'E-Mail Adresse',
  'survey.placeholderCompany': 'Firmenname (optional)',
  'survey.continueButton': 'Weitermachen',
  'survey.outroTitle': 'Vielen Dank',
  'survey.outroDescriptionOne': 'Jemand von CodeControl wird Ihnen in Kürze eine E-Mail schicken.',
  'survey.outroDescriptionTwo': 'Keine Zeit zum Warten? Schicken Sie uns einfach eine E-Mail an',
  'survey.outroSubtitle': ' Oder folgen Sie uns für Neuigkeiten und Events!',
  'landingpage.contactform.email': 'Business Email',
  'landingpage.contactform.name': 'Name',
  'landingpage.contactform.phone': 'Telefonnummer',
  'landingpage.contactform.about': 'Erzähle uns über dein Projekt',
  'landingpage.contactform.submit': 'Senden',
  'landingpage.contactform.error.validEmail': 'Bitte eine gültige Email Adresse angeben',
  'landingpage.contactform.error.requiredEmail': 'Bitte Email Adresse angeben',
  'landingpage.contactform.error.requiredPhone': 'Bitte Telefonnummer angeben',
  'landingpage.contactform.error.requiredName': 'Bitte vollständigen Namen angeben',
  'landingpage.contactform.sentTitle': 'We have sent your message!',
  'landingpage.contactform.sentSubheading': 'We will get back to you on {email} or by phone.',
  'developers.meta.description': 'Are you looking for a talented developer for your project? Find the right talent from the top freelancers with CodeControl.',
  'developers.cta.getInTouch': 'Jetzt Einstellen',
  'developers.cta.getAQuote': 'Angebot Erhalten',
  'developers.teaser1.title': 'Stelle top {prefix} {skill}-{jobTitlePlural} ein.',
  'developers.teaser1.subtitle': 'Entdecke Top-Talente Für Dein Projekt.',
  'developers.teaser1.copy': 'Es war noch nie so einfach Freelancer zu finden',
  'developers.teaser1.linkSubtext': '<p>Meld dich bei uns für eine kostenlose Beratung:</p>\n<p><a href="tel:+4903030808013" target="_blank" rel="nofollow"><strong>+49 (0) 30 30808013</strong></a></p>',
  'developers.teaser2.title': '{skill}-{jobTitlePlural} - bereit für dich durchzustarten',
  'developers.grid.title': '{skill}-{jobTitleSingular}',
  'developers.grid.titleSenior': 'Senior {skill}-{jobTitleSingular}',
  'developers.timeline1.step1.title': 'Mehr als 30 professionelle {skill}-{jobTitlePlural}',
  'developers.timeline1.step2.title': 'Startklar innerhalb von 3 Tagen',
  'developers.timeline1.step3.title': 'Erfolgreich tätig für bekannte Startups und Tech-Konzerne',
  'developers.teaser3.title': 'DU BIST IN GUTER GESELLSCHAFT',
  'developers.teaser3.copy': '<blockquote>\n<p>“Wir arbeiten mit CodeControl an Innovationsprojekten. Es ist wirklich erfrischend! CodeControl’s Schnelligkeit, Flexibilität und die Qualität ihrer Arbeit sind herausragend. Wir empfehlen sie sehr für jedes digitale Projekt.</p>\n</blockquote>\n<blockquote>\n<p>Rafija Karadza – E-Commerce Specialist\n<strong>DM DROGERIE MARKT ÖSTERREICH</strong></p>\n</blockquote>',
  'developers.teaser4.title': 'Gründe für {skill}-{jobTitlePlural} von CodeControl',
  'developers.teaser4.subtitle': 'The Future of Work',
  'developers.teaser5.title': 'Du rekrutierst aus den Top {skill}-{jobTitlePlural}',
  'developers.teaser5.copy': '<p>Bei CodeControl arbeiten wir nur mit den besten Talenten, die es durch unseren strengen Auswahlprozess geschafft haben. Wenn du mit uns arbeitest, hast du Zugang zu unserer Community der Top Tech-Talente.</p>\n<p>Egal, woran du arbeitest, wir liefern dir einen Entwickler, der über genau die Erfahrung verfügt, die für deine freie Position wichtig ist. Zu oft besetzen Unternehmen Positionen mit irgendwelchen Mitarbeitern, die eh schon angestellt sind.</p>',
  'developers.teaser6.title': 'Du findest den perfekten Kandidaten innerhalb von 48 Stunden',
  'developers.teaser6.copy': "<p>Für immer mehr Unternehmen ist die Langsamkeit von Rekrutierungsprozessen ein Hindernis im Tagesgeschäft. Mit unseren Freelancern läuft dein Unternehmen jederzeit auf Hochtouren weiter.</p>\n<p>Anstatt bis zu 42 Tage mit Headhunting zu verbringen, hast du den perfekten Kandidaten innerhalb von 48 Stunden. Er kann direkt für dich durchstarten.</p>",
  'developers.teaser7.title': 'Du sparst dir den mühsamen Auswahlprozess',
  'developers.teaser7.copy': '<p>Du musst nicht mehr deine Zeit damit verbringen, die Programmierfähigkeiten von Entwicklern zu beurteilen. Unsere Freelance Entwickler haben bereits einen dreistufigen Auswahlprozess durchlaufen.</p>',
  'developers.teaser8.title': 'Du verringerst dein Risiko',
  'developers.teaser8.copy': '<p>Wenn du mit CodeControl arbeitest, kannst du immer sicher sein, dass dein Projekt erfolgreich abgeschlossen wird. Nachdem wir dir den passenden Kandidaten vermittelt haben, kontrollieren wir seine Leistung, um zu garantieren, dass du zufrieden bist.</p>',
  'developers.teaser9.title': 'So findest du im Handumdrehen erstklassige Talente.',
  'developers.teaser9.subtitle': 'Wie Es Funktioniert',
  'developers.timeline2.step1.title': 'Deine Bedürfnisse verstehen.',
  'developers.timeline2.step1.description': '<p>Sende uns zuerst eine Nachricht. Telefoniere dann schnell mit unserem Team um zu klären, was für einen Talent du benötigst.</p>',
  'developers.timeline2.step2.title': 'Wir verbinden dich mit Top-Talenten.',
  'developers.timeline2.step2.description': '<p>Innerhalb von zwei Tagen senden wir dir das Profil eines idealen Kandidaten zu oder beginnen mit der Zusammenstellung eines Projektteams für dich.</p>',
  'developers.timeline2.step3.title': 'Kommen wir zur Sache.',
  'developers.timeline2.step3.description': '<p>Lerne deinen Kandidat während eines Gesprächs kennen - wenn ihr zufrieden seid, könnt ihr sofort mit der Zusammenarbeit beginnen! Wir kümmern uns um die gesamte Admin.</p>',
  'developers.timeline2.step4.title': 'Performance-Überwachung.',
  'developers.timeline2.step4.description': '<p>Wir überwachen weiterhin die Leistung des Freelancers und sorgen dafür, dass du zufrieden bist. Wenn nicht, finden wir einen Ersatzkandidaten für dich.</p>',
  'developers.faq.title': 'Was möchtest du gerne wissen?',
  'developers.faq.subtitle': 'Frequently Asked Questions',
  'developers.faq.q1.title': "Wie funktioniert's?",
  'developers.faq.q1.answer': "<p>Kontaktiere uns (klicke bitte auf den Button unten!) und plane einen Anruf mit unserem Team. Sobald wir deine Bedürfnisse verstanden haben, senden wir dir das Profil eines idealen Kandidaten zu und stellen ihn/sie dir während eines Anrufs vor. Wenn ihr beide zufrieden seid, könnt ihr direkt mit der Zusammenarbeit beginnen - wir kümmern uns um die Verträge.</p>",
  'developers.faq.q2.title': 'Wie schnell kann jemand anfangen?',
  'developers.faq.q2.answer': "<p>Sobald wir wissen, was du benötigst, durchsuchen wir unsere Datenbank, um einen Freelancer mit der passenden Fähigkeit und Verfügbarkeit zu finden. Innerhalb von zwei Tagen erhältst du das Profil unserer Top-Empfehlung und kannst sofort mit der Zusammenarbeit beginnen. Wenn du mehr Optionen sehen möchtest, können wir dir diese auch senden.</p>",
  'developers.faq.q3.title': 'Wie funktionieren die Preisgestaltung und die Bezahlung?',
  'developers.faq.q3.answer': "<p>Die Preisgestaltung hängt in der Regel von den spezifischen Fähigkeiten (und der gesprochenen Sprache) ab, die du benötigst. Kontaktiere uns, um das Projekt zu spezifizieren und einen Kostenvoranschlag zu erhalten. Die Abrechnung erfolgt am Ende eines jeden Monats nach der Anzahl der vom Freelancer geleisteten Arbeitsstunden.</p>",
  'developers.faq.q4.title': 'Was passiert, wenn es ein Problem gibt?',
  'developers.faq.q4.answer': "<p>Wir überwachen weiterhin die Leistung des Freelancers und sorgen dafür, dass du zufrieden bist. Sollte dies aus irgendeinem Grund nicht der Fall sein, finden wir im Handumdrehen einen Ersatz für dich!</p>",
  'developers.teaser10.title': 'Kontaktiere uns für einen Kostenvoranschlag.',
  'developers.teaser10.subtitle': 'Wie Viel Kostet Ein Entwickler?',
  'developers.contact.title': 'Du brauchst Hilfe bei deinem Projekt? Lass uns reden!',
  'projects.meta.description': 'Lass CodeControl ein Projektteam für dich zusammenstellen. Nimm Kontakt mit uns auf und wir bauen dir ein maßgeschneidertes Team aus Entwicklern, Designern und Produktmanagern - in nur 5 Tagen.',
  'projects.cta.getInTouch': 'Projekt Starten',
  'projects.cta.startHiring': 'Jetzt Einstellen',
  'projects.cta.getAQuote': 'Angebot Erhalten',
  'projects.cta.scheduleCall': 'Anruf Planen',
  'projects.teaser1.title': 'Entspann dich. Dein {skill}-Projekt ist bei uns in guten Händen.',
  'projects.teaser1.subtitle': '',
  'projects.teaser1.copy': 'Lass uns {article} {skill}-{jobTitleSingular}team zusammenstellen.',
  'projects.teaser1.linkSubtext': '<p>Meld dich bei uns für eine kostenlose Beratung:</p>\n<p><a href="tel:+4903030808013" target="_blank" rel="nofollow"><strong>+49 (0) 30 30808013</strong></a></p>',
  'projects.teaser2.title': 'Maßgeschneiderte {skill}-Teams, die bereit sind, dein Projekt in Angriff zu nehmen.',
  'projects.teaser2.subtitle': 'ZUVERLÄSSIGE, ERFAHRENE TALENTE',
  'projects.timeline1.step1.title': 'ZWECKGEBUNDENE TEAMS.',
  'projects.timeline1.step1.description': 'Wir stellen ein maßgeschneidertes Team aus Top-Entwicklern, Designern und Produktmanagern zusammen, um deine Anforderungen zu erfüllen.',
  'projects.timeline1.step2.title': 'SCHNELLE PROJEKTBESETZUNG.',
  'projects.timeline1.step2.description': 'Schnell besetzte Projekte, dein Team von Freelancern ist in weniger als 5 Tagen einsatzbereit.',
  'projects.timeline1.step3.title': 'VOLLSTÄNDIGE PROJEKTIERUNG.',
  'projects.timeline1.step3.description': 'Wir unterstützen Sie bei der Definierung deiner genauen Bedürfnisse - und deren Erfüllung - von Anfang bis Ende.',
  'projects.grid.title': '{skill} {jobTitleSingular}',
  'projects.grid.titleSenior': 'Senior {skill} {jobTitleSingular}',
  'projects.teaser3.title': 'Was sind deine Ziele, dein Budget und dein Zeitrahmen?',
  'projects.teaser3.subtitle': 'WIR REALISIEREN DEINE PROJEKTE.',
  'projects.teaser3.copy': "<p>Abhängig von dem Produkt, das du entwickeln möchtest, deiner Zeitrahmen-, Budget- und aktuellen Teamstruktur, stellen wir ein Team zusammen, das sich nahtlos in dein Unternehmen einfügt.</p><p>Volle Unterstützung bei der Entwicklung unübertroffener Produkte.</p>",
  'projects.teaser4.title': 'DU BIST IN GUTER GESELLSCHAFT',
  'projects.teaser4.subtitle': 'LEADER KREIEREN MIT CODECONTROL',
  'projects.teaser5.title': 'Unser Prozess mit Projektteams.',
  'projects.teaser5.subtitle': 'UNTERSTÜTZUNG IN JEDER PHASE.',
  'projects.slider1.slide1.title': 'Projektumfang.',
  'projects.slider1.slide1.subtitle': 'Deine Bedürfnisse verstehen.',
  'projects.slider1.slide1.copy': '<p>Die Anforderungsanalyse muss nicht langweilig sein. Besprich und kläre zuerst deine Projektvision und -ziele mit unserem Team.</p><p>Ziele dieser Phase:</p><ul><li>Konzept definieren</li><li>Erforderliche Kompetenzen ermitteln</li><li>Zeitrahmen festlegen</li><li>Ziele setzen</li></ul>',
  'projects.slider1.slide2.title': 'Start.',
  'projects.slider1.slide2.subtitle': 'Start des Projekts.',
  'projects.slider1.slide2.copy': '<p>Wir stellen dein Projektteam innerhalb von 48 Stunden zusammen, einschließlich Designer, Entwickler und PMs nach Bedarf. Zufrieden mit dem Team? Dann könnt ihr direkt mit der Arbeit anfangen!</p><p>Ziele dieser Phase:</p><ul><li>Mit einem Workshop beginnen</li><li>Ansatz entwickeln</li><li>Team zusammenstellen</li><li>Hauptakteure einbeziehen</li></ul>',
  'projects.slider1.slide3.title': 'Agile Sprints.',
  'projects.slider1.slide3.subtitle': 'Implementierung und Iteration.',
  'projects.slider1.slide3.copy': '<p>Deine Vision wird Wirklichkeit: Das Projektteam entwickelt dein Produkt in zweimonatlichen Sprints. Unsere agile Methode ermöglicht es ihnen, Termine und Budgets einzuhalten.</p><p>Ziele dieser Phase:</p><ul><li>Das Erlebnis gestalten</li><li>“Commit” Code</li><li>mit Benutzern testen</li><li>Wiederholen</li></ul>',
  'projects.slider1.slide4.title': 'Projektabwicklung.',
  'projects.slider1.slide4.subtitle': 'Live Launch.',
  'projects.slider1.slide4.copy': '<p>Es ist Zeit, dein Produkt online zu stellen. Wir und unser Projektteam stehen dir bei jedem Schritt des Prozesses zur Seite und unterstützen dich, bis alles perfekt ist.</p><p>Ziele dieser Phase:</p><ul><li>Markteinführung</li><li>Nutzung überwachen</li><li>Benutzerfeedback sammeln</li><li>Wachstum planen</li></ul>',
  'projects.teaser6.title': 'Einige nette Meinungen über uns.',
  'projects.teaser6.subtitle': 'KUNDEN ERZÄHLEN',
  'projects.slider2.slide1.name': 'Lufthansa Innovation Hub',
  'projects.slider2.slide1.quote': '<p>Endlich eine Leistung die volle Kontrolle und Transparenz anbietet, während die Software aufgebaut wird und skalierbar ist. Ein Traum!</p>',
  'projects.slider2.slide1.quoteGiver': 'Marc Schmöger | Senior Product Manager',
  'projects.slider2.slide2.name': 'Coya AG',
  'projects.slider2.slide2.quote': '<p>Wir brauchten Unterstützung bei der Backend-Entwicklung, so dass CodeControl uns mit Fernando, einem Senior DevOps-Ingenieur, und Aleksandar, einem Elm-Entwickler, zusammengeführt hat. Beide waren talentiert, konnten schnell mit der Arbeit beginnen und die Kommunikation war sehr gut.</p>',
  'projects.slider2.slide2.quoteGiver': 'Andrew Shaw | CEO',
  'projects.slider2.slide3.name': 'Siemens Mobility',
  'projects.slider2.slide3.quote': '<p>CodeControl unterstützt uns bei der Entwicklung erster Kundenanwendungen auf der Siemens IoT-Plattform Mindsphere. Mit ihrem hochmotivierten und kompetenten Team erweisen sie sich als der perfekte Partner für digitale Innovationsprojekte.</p>',
  'projects.slider2.slide3.quoteGiver': 'R.D. - Global Product Manager Connectivity & IoT',
  'projects.slider2.slide4.name': 'DM DROGERIE MARKT ÖSTERREICH',
  'projects.slider2.slide4.quote': '<p>Wir arbeiten mit CodeControl an Innovationsprojekten. Es ist wirklich erfrischend! CodeControl’s Schnelligkeit, Flexibilität und die Qualität ihrer Arbeit sind herausragend. Wir empfehlen sie sehr für jedes digitale Projekt. </p>',
  'projects.slider2.slide4.quoteGiver': 'Rafija Karadza – E-Commerce Specialist',
  'projects.teaser7.title': 'Welche Art von Produkt kreierst du?',
  'projects.teaser7.subtitle': 'UNSERE PRODUKTLÖSUNGEN',
  'projects.teaser7.copy': '<p>Unsere Freelancer haben Erfahrung in zahlreichen Fachgebieten, so dass wir für dich das optimale Team zusammenstellen können, egal woran du arbeitest. Beginne einfach uns mitzuteilen was du bauen möchtest.</p>',
  'projects.teaser8.title': 'Kontaktiere uns für einen Kostenvoranschlag.',
  'projects.teaser8.subtitle': 'WIE VIEL KOSTET EIN TEAM.',
  'projects.faq.title': 'Was möchtest du gerne wissen?',
  'projects.faq.subtitle': 'HÄUFIG GESTELLTE FRAGEN',
  'projects.faq.q1.title': 'Wie funktioniert\'s?',
  'projects.faq.q1.answer': "Kontaktiere uns (klicke bitte auf den Button unten!) und plane einen Anruf mit unserem Team. Sobald wir deine Bedürfnisse verstanden haben, senden wir dir Profile der idealen Team-Kandidaten zu und stellen sie dir während eines Anrufs vor. Wenn ihr alle zufrieden seid, könnt ihr direkt mit der Zusammenarbeit beginnen - wir kümmern uns um die Verträge.",
  'projects.faq.q2.title': 'Wie schnell kann man anfangen?',
  'projects.faq.q2.answer': "Sobald wir wissen, was du benötigst, durchsuchen wir unsere Datenbank, um Freelancer mit den passenden Fähigkeiten und Verfügbarkeit für das Projekt zu finden. Innerhalb von zwei Tagen erhältst du die Profile unserer Top-Empfehlungen und kannst sofort mit der Zusammenarbeit beginnen. Wenn du mehr Optionen sehen möchtest, können wir dir diese auch senden.",
  'projects.faq.q3.title': 'Wie funktionieren die Preisgestaltung und die Bezahlung?',
  'projects.faq.q3.answer': "Die Preisgestaltung hängt in der Regel von den spezifischen Fähigkeiten (und der gesprochenen Sprache) ab, die du benötigst. Kontaktiere uns, um das Projekt zu spezifizieren und einen Kostenvoranschlag zu erhalten. Die Abrechnung erfolgt am Ende eines jeden Monats nach der Anzahl der vom Projektteam geleisteten Arbeitsstunden.",
  'projects.faq.q4.title': 'Was passiert, wenn es ein Problem gibt?',
  'projects.faq.q4.answer': "Wir überwachen weiterhin die Leistung des Projektteams und sorgen dafür, dass du zufrieden bist. Sollte dies aus irgendeinem Grund nicht der Fall sein, finden wir im Handumdrehen Ersatz für dich!",
  'projects.contact.title': 'Du brauchst Hilfe bei deinem Projekt? Lass uns reden!',
  'clients.project.details': 'Details'
}
