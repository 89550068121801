import * as React from 'react'

import styled, { css } from 'styled-components'
import Page from '../components/Page'

import Helmet from 'react-helmet'
import { Field, Form, FormikProps, withFormik } from 'formik'
import { navigate, RouteComponentProps, WindowLocation } from '@reach/router'
import { TelephoneIcon } from '../components/icons/TelephoneIcon'
import {
  colors,
  brandColors,
  Input,
  LogosDisplay,
  PersonIcon,
  LetterIcon,
  media,
  Row,
  Heading,
  Text,
  Button,
  CodeControlIcon,
  onEvent,
} from 'cc-ui-components'
import * as Yup from 'yup'
import { History } from 'history'
// import * as SegmentAnalytics from '../lib/segment'
import { storeLead, contactToSource } from '../lib/codecontrol'
import { CalendlyButton } from '../components/common/Calendly'
import PhoneInput from 'react-phone-number-input'
import { getLanguageCode } from '../styles/utils'
import LegalSection from '../components/common/LegalSection'
import IndexLayout from '../components/layout'
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl'
import { logos } from '../styles/static'
import Link from '../components/common/Link'

interface FieldWrapperProps {
  hideIcon?: boolean
  phoneInputFocused?: boolean
}

const ContactFormWrapper = styled.div`
  width: 370px;
  margin-top: 32px;
  margin-bottom: 64px;
  button {
    width: 100%;
  }
  .react-phone-number-input {
    width: 100%;
    &__row {
      display: flex;
      justify-content: space-between;
    }
    &__input {
      height: auto;
      width: calc(100% - 100px) !important;
      padding: 16px 0 0 16px;
      border-radius: 4px;
      border: 1px solid ${brandColors.darkgrey2};
      z-index: 5;
      background: transparent;
      outline: none;
    }
    &__country {
      width: 80px;
      height: 58px;
      position: relative;
      border: 1px solid ${brandColors.darkgrey2};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
    &__icon {
      width: 24px;
      height: 13.8px;
      margin-right: 12px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &__country-select {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }
    &__country-select-arrow {
      display: block;
      content: '';
      width: 0;
      height: 0;
      margin-bottom: 0.1em;
      margin-top: 0.3em;
      margin-left: 0.3em;
      border-width: 6px 6px 0 6px;
      border-style: solid;
      border-left-color: transparent;
      border-right-color: transparent;
      color: ${colors.text};
      opacity: 1;
    }
  }
  ${media.tablet`
    width: 100%;
    margin-bottom: 64px;
    margin-top: 30px;
    button {
      height: 46px;
    }
  `};
`

export const FieldWrapper = styled.div`
  position: relative;
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
  ${(props: FieldWrapperProps) =>
    props.hideIcon &&
    css`
      .react-phone-number-input__icon {
        svg {
          display: none;
        }
      }
    `};
  ${(props: FieldWrapperProps) =>
    props.phoneInputFocused &&
    css`
      input {
        border: 1px solid ${colors.primary} !important;
      }
    `};
`

const FormText = styled.div`
  text-align: center;
  p {
    margin: 16px 0;
  }
`

export const IconWrapper = styled.div`
  max-width: 15px;
  max-height: 20px;
  margin-right: 10px;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const FormIconWrapper = styled(IconWrapper)`
  position: absolute;
  bottom: 20px;
  left: 4px;
  display: block;
`

const StyledFeedback = styled.div`
  color: ${brandColors.red};
  font-size: 10px;
  text-align: right;
  padding-right: 5px;
  width: 100%;
`

const TelephoneIconSpan = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 16px;
  left: 16px;
`

interface PlaceholderProps {
  active: boolean
  small: boolean
}

const Placeholder = styled.span`
  position: absolute;
  bottom: ${(props: PlaceholderProps) => (props.small ? 33 : 16)}px;
  left: 116px;
  color: ${(props: PlaceholderProps) =>
    props.active ? colors.primary : colors.lightText};
  font-weight: 400;
  line-height: 24px;
  font-size: ${(props: PlaceholderProps) => (props.small ? 12 : 15)}px;
  transition: all 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`

interface FormValues {
  name: string
  email: string
  phone: string
  isValid?: boolean
  phoneInputFocused: boolean
}

interface InnerFormProps extends InjectedIntlProps {
  userLocation?: {
    country: string
  }
}

const InnerForm = (props: FormikProps<FormValues> & InnerFormProps) => {
  const {
    intl,
    submitCount,
    touched,
    isValid,
    errors,
    isSubmitting,
    values,
    setFieldValue,
    userLocation,
  } = props
  if (isSubmitting && isValid) {
    navigate(intl.formatMessage({ id: 'path.thank-you' }))
  }
  const country =
    userLocation && userLocation.country ? userLocation.country : null
  const hideCountryFlag = !country
  return (
    <Form>
      <ContactFormWrapper>
        <FieldWrapper>
          <FormIconWrapper>
            <PersonIcon stroke={colors.inverseText} fill={colors.inverseText} />
          </FormIconWrapper>
          <FormattedMessage id={'global.full_name'}>
            {text => (
              <Field
                name="name"
                render={({ field }: any) => (
                  <Input
                    name={field.name}
                    variant={'boxed-material'}
                    placeholder={text.toString()}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            )}
          </FormattedMessage>

          <StyledFeedback>
            {touched.name &&
              submitCount > 0 &&
              errors.name &&
              intl.formatMessage({ id: errors.name })}
          </StyledFeedback>
        </FieldWrapper>

        <FieldWrapper>
          <FormIconWrapper>
            <LetterIcon fill={colors.inverseText} />
          </FormIconWrapper>
          <FormattedMessage id={'global.email'}>
            {text => (
              <Field
                name="email"
                render={({ field }: any) => (
                  <Input
                    type={'email'}
                    name={field.name}
                    variant={'boxed-material'}
                    placeholder={text.toString()}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            )}
          </FormattedMessage>
          <StyledFeedback>
            {touched.email &&
              submitCount > 0 &&
              errors.email &&
              intl.formatMessage({ id: errors.email })}
          </StyledFeedback>
        </FieldWrapper>

        <FieldWrapper
          hideIcon={hideCountryFlag}
          phoneInputFocused={values.phoneInputFocused}
        >
          <FormattedMessage id={'global.phone'}>
            {text => (
              <Placeholder
                small={!!values.phone || values.phoneInputFocused}
                active={values.phoneInputFocused}
              >
                {text}
              </Placeholder>
            )}
          </FormattedMessage>
          <PhoneInput
            placeholder={''}
            value={values.phone}
            onChange={(value: string) => setFieldValue('phone', value)}
            country={userLocation ? userLocation.country : null}
            onFocus={() => setFieldValue('phoneInputFocused', true)}
            onBlur={() => setFieldValue('phoneInputFocused', false)}
          />
          {hideCountryFlag && (
            <TelephoneIconSpan>
              <TelephoneIcon />
            </TelephoneIconSpan>
          )}
        </FieldWrapper>
        <StyledFeedback>
          {touched.phone &&
            submitCount > 0 &&
            errors.phone &&
            intl.formatMessage({ id: errors.phone })}
        </StyledFeedback>
        <FormText>
          <Text size={'xs'} light fontWeight={400}>
            <FormattedMessage id={'contact.email_section.footer'} />
          </Text>
        </FormText>
        {!isSubmitting && (
          <Button
            flat
            text={intl.formatMessage({ id: 'contact.email_section.CTA' })}
            type={'submit'}
            center
            medium
          />
        )}
      </ContactFormWrapper>
    </Form>
  )
}

interface FormProps {
  initialEmail?: string
  userLocation?: any
  onError?: (error: any) => void
  history?: History
}

const ContactForm = withFormik<FormProps, FormValues>({
  mapPropsToValues: props => {
    return {
      name: '',
      email: props.initialEmail || '',
      phoneInputFocused: false,
      phone: '',
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('get-in-touch.requiredName'),
    email: Yup.string()
      .email('get-in-touch.validEmail')
      .required('get-in-touch.requiredEmail'),
    phone: Yup.string().required('get-in-touch.requiredPhone'),
  }),
  handleSubmit: values => {
    const { name, email, phone } = values
    const lowercaseEmail = email.toLowerCase()
    return storeLead(
      { email: lowercaseEmail, name: name || '', phone: phone || '' },
      contactToSource('Submitted Contact Form')
    )
  },
})(injectIntl(InnerForm))

export const CustomRow = styled(Row)`
  padding: 0;
  flex-wrap: unset;
  display: flex;
  min-height: 100%;
  margin: 0;
  & > div {
    &:first-of-type {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  ${media.tablet`
    flex-direction: column;
    h2 {
      font-size: 24px;
      font-height: 30px;
    }
    h3 {
      font-size: 15px;
      line-height: 24px;
    }
    form {
      width: 100%;
    }
  `};
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
`

export const LogosWrapper = styled.div`
  & > div > div > div > div {
    flex-wrap: wrap;
    max-width: 360px;
    justify-content: center;
    align-items: center;
    align-content: center;
    & > div {
      flex-basis: 50%;
      margin: 0 auto 32px;
      max-width: 164px;
      height: 104px;
      min-width: 130px;
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  ${media.sm`
    & > div > div > div > div {
      & > div {
        height: 84px;
        padding: 0 25px;
      }
    }
  `};
`

export const HomepageLink = styled(Link)`
  ${onEvent`
    text-decoration: none;
  `};
`

export const CCLogo = styled.div`
  width: 70px;
  height: 70px;
  margin-bottom: 40.5px;
  svg {
    width: 100%;
    height: auto;
    path {
      fill: ${colors.text};
    }
  }
`

const fetchUserLocation = (): Promise<string> => {
  return new Promise(resolve => {
    fetch('https://ipapi.co/json/').then(res => {
      res.json().then(data => {
        resolve(data)
      })
    })
  })
}

interface IState {
  userLocation?: string | null
}

export const CustomColLeft = styled.div`
  background-color: ${colors.bodyBackground};
  flex-basis: 100%;
  flex-shrink: 1;
  padding: 72px 48px;
  ${media.tablet`
    padding: 48px 24px;
  `};
`

export const ContentWrapper = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 520px;
`

export const CustomColRight = styled.div`
  flex-basis: 460px;
  padding: 72px 48px;
  flex-shrink: 0;
  position: relative;
  & > div {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 48px;
    z-index: 1;
  }
  &:after {
    content: '';
    background: ${colors.lightBackground};
    position: fixed;
    top: 0;
    right: 0;
    width: 460px;
    height: 100vh;
    z-index: 0;
  }
  ${media.tablet`
    background: ${colors.lightBackground};
    padding: 48px 24px;
    &:after {
      display: none;
    }
    & > div {
      position: unset;
      transform: unset;
    }
  `};
`

export const Logos = () => (
  <LogosDisplay
    items={[
      logos.mymuesli,
      logos.orderBird,
      logos.lufthansa,
      logos.careship,
      logos.universal,
      logos.siemens,
      logos.accenture,
      logos.dm,
    ]}
  />
)

export const HubspotContactFormContainer = styled.div`
  width: 500px;
  margin-top: 30px;
  margin-bottom: 30px;
  ${media.sm`
    width: 85%;
  `};
`

class HubspotContactForm extends React.Component<any> {
  public componentDidMount() {
    this.initializeHubspotForm()
  }
  initializeHubspotForm() {
    if ('hbspt' in window) {
      // Just to make sure the script is loaded (you can also do it by attaching an `onload` event to the script but I did it in this way
      // @ts-ignore
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '19527136',
        formId: '2d358afb-030a-42f8-9305-7ca4e83beeeb',
        sfdcCampaignId: '7011v000000GLL9AAO',
        target: '#hubspot-contact-form',
      })
    } else {
      setTimeout(this.initializeHubspotForm, 500)
    }
  }

  public render() {
    return <HubspotContactFormContainer id="hubspot-contact-form" />
  }
}

class GetInTouch extends React.Component<any & RouteComponentProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      userLocation: null,
    }
  }

  public componentDidMount() {
    fetchUserLocation().then(userLocation => {
      if (userLocation) {
        this.setState({
          userLocation,
        })
      }
    })

    /*const title = this.props.intl.formatMessage({ id: 'contact.title' })
    requestAnimationFrame(() => {
      SegmentAnalytics.page(`Contact / ${title}`)
    })*/
  }

  public render() {
    const lang = getLanguageCode()

    return (
      <Page>
        <FormattedMessage id={'contact.title'}>
          {title => (
            <Helmet>
              <title>{title.toString()}</title>
            </Helmet>
          )}
        </FormattedMessage>
        <Helmet htmlAttributes={{ lang }}>
          <meta
            name="description"
            content="Let us help you! We are waiting for for you. So get in contact…"
          />
          <link
            href="https://assets.calendly.com/assets/external/widget.css"
            rel="stylesheet"
          />
          <script
            src="https://assets.calendly.com/assets/external/widget.js"
            type="text/javascript"
          />
        </Helmet>
        <Wrapper>
          <CustomRow>
            <CustomColLeft>
              <ContentWrapper>
                <HomepageLink to={`/${lang}/home`}>
                  <CCLogo>
                    <CodeControlIcon />
                  </CCLogo>
                </HomepageLink>
                <Heading level="2" size="xl" center>
                  <FormattedMessage id={'contact.headline'} />
                </Heading>
                <HubspotContactForm />
                <Heading level="2" size="xl" trailer={1} center>
                  <FormattedMessage id={'contact.calendly_section.headline'} />
                </Heading>
                <Heading level="3" size="md" secondary trailer={1.2} center>
                  <FormattedMessage id={'contact.calendly_section.subline'} />
                </Heading>
                <CalendlyButton
                /*onClick={() => {
                    SegmentAnalytics.trackEvent('Opened Calendly Form')
                  }}*/
                />
                <LegalSection hideTerms />
              </ContentWrapper>
            </CustomColLeft>

            <CustomColRight>
              <LogosWrapper>{Logos()}</LogosWrapper>
            </CustomColRight>
          </CustomRow>
        </Wrapper>
      </Page>
    )
  }

  protected handleError = (error: any) => {
    console.log(error)
  }
}

const GetInTouchIntl = injectIntl(GetInTouch)

interface WrapperProps {
  location: WindowLocation | undefined
}

const GetInTouchWrapper: React.SFC<WrapperProps> = props => (
  <IndexLayout location={props.location}>
    <GetInTouchIntl {...props} />
  </IndexLayout>
)

export default GetInTouchWrapper
