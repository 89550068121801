import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { onEvent } from 'cc-ui-components'

const Link = styled(GatsbyLink)`
  color: inherit;

  ${onEvent`
    text-decoration: none;
  `};
`

export default Link
