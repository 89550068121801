import * as React from 'react'
import { Button } from 'cc-ui-components'
import styled from 'styled-components'
import { getCalendlyLink, getLanguageCode } from '../../styles/utils'
import { logos } from '../../styles/static'
import { colors } from 'cc-ui-components/dist'

interface ButtonProps {
  onClick: () => void
}

interface ButtonState {
  loaded: boolean
}

const CalendlyImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 24px;
`

const CalendlyWrapper = styled.div`
  margin-top: 1rem;
  & > div {
    width: 170px;
  }
`

export class CalendlyButton extends React.Component<ButtonProps, ButtonState> {
  constructor(props: ButtonProps) {
    super(props)
    this.state = { loaded: false }
  }

  public componentDidMount() {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = 'https://assets.calendly.com/assets/external/widget.js'
    s.addEventListener('load', () => {
      this.setState(() => ({ loaded: true }))
    })
    document.body.appendChild(s)

    const l = document.createElement('link')
    l.href = 'https://assets.calendly.com/assets/external/widget.css'
    l.rel = 'stylesheet'
    document.body.appendChild(s)

    const st = document.createElement('style')
    st.type = 'text/css'
    st.innerText = '.calendly-badge-widget {display: none}'
    document.body.appendChild(st)
  }

  public render() {
    const { loaded } = this.state
    const buttonUrl = `${getCalendlyLink(
      getLanguageCode()
    )}?utm_source=website&utm_medium=contact_page`
    return (
      <CalendlyWrapper>
        <Button
          onClick={() => {
            this.handleClick(this.props.onClick)
          }}
          flat
          center
          textColor={colors.text}
          border={'none'}
          background={'#e0e0e0'}
          text={''}
        >
          <CalendlyImg src={logos.calendly} alt="" />
        </Button>

        {// @ts-ignore
        loaded &&
          Calendly.initBadgeWidget({
            url: buttonUrl,
            text: 'Schedule time with us',
            color: '#4d5055',
            branding: false,
          })}
      </CalendlyWrapper>
    )
  }

  private handleClick(callBack: () => void) {
    if (callBack) {
      callBack()
    }
    const badge = document.getElementsByClassName(
      'calendly-badge-content'
    )[0] as HTMLElement
    badge.click()
  }
}
