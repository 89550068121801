import * as React from 'react'

import { media, colors } from 'cc-ui-components'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { InjectedIntlProps, injectIntl } from 'react-intl'

interface LegalSectionProps extends InjectedIntlProps {
  hideTerms?: boolean
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  justify-content: center;
  a {
    color: ${colors.lightText};
    text-decoration: none;
    margin-right: 2em;
    text-transform: uppercase;
    font-size: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  ${media.sm`
    margin-top: 30px;
  `};
`

const LegalSection: React.SFC<LegalSectionProps> = ({ hideTerms, intl }) => (
  <Wrapper>
    <Link to={intl.formatMessage({ id: 'path.imprint' })}>
      {' '}
      {intl.formatMessage({ id: 'global.imprint' })}
    </Link>
    <Link to={intl.formatMessage({ id: 'path.privacy' })}>
      {' '}
      {intl.formatMessage({ id: 'global.privacy' })}
    </Link>
    {!hideTerms && (
      <Link to={intl.formatMessage({ id: 'path.terms' })}>
        {' '}
        {intl.formatMessage({ id: 'global.terms' })}
      </Link>
    )}
  </Wrapper>
)

export default injectIntl(LegalSection)
