import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
`

interface PageProps {
  children: any
  id?: string
}

const Page: React.SFC<PageProps> = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
)

export default Page
