import * as React from 'react'
import Helmet from 'react-helmet'

import { GlobalStyle } from '../styles/normalize'
import {
  colors,
  GlobalStyle as GlobalStyleUiComponents,
  themes,
} from 'cc-ui-components'

import Footer from '../components/Footer'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'

// import CookieConsent from 'react-cookie-consent'

import { getCurrentLangKey } from 'ptz-i18n'
import { /*FormattedMessage,*/ IntlProvider } from 'react-intl'

import { graphql, StaticQuery } from 'gatsby'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

const translations = require('../data/translations')

interface WrapperProps {
  children: any
  hideFooter?: boolean
  data?: {
    site: {
      siteMetadata: {
        title: string
        description: string
        languages: {
          langs: string[]
          defaultLangKey: string
        }
      }
    }
  }
}

class IndexLayout extends React.Component<WrapperProps & RouteComponentProps> {
  public render() {
    const { location, hideFooter } = this.props
    const path = location ? location.pathname : '/'

    return (
      <StaticQuery
        query={graphql`
          query IndexLayoutQuery {
            site {
              siteMetadata {
                title
                description
                languages {
                  defaultLangKey
                  langs
                }
              }
            }
          }
        `}
        render={data => {
          const { langs, defaultLangKey } = data.site.siteMetadata.languages
          const langKey = getCurrentLangKey(langs, defaultLangKey, path)
          const i18nMessages = require(`../data/translations/${langKey}`)
          const defaultTheme = 'regular'

          const showNavElements = ![
            `/${langKey}/get-in-touch`,
            `/${langKey}/get-in-touch/`,
            `/${langKey}/thank-you`,
            `${translations.de['path.thank-you']}/`,
            `${translations.de['path.thank-you']}`,
            `${translations.fr['path.thank-you']}/`,
            `${translations.fr['path.thank-you']}`,
            `${translations.de['path.get-in-touch']}/`,
            `${translations.de['path.get-in-touch']}`,
            `${translations.fr['path.get-in-touch']}/`,
            `${translations.fr['path.get-in-touch']}`,
            `/${langKey}/thank-you/`,
          ].includes(path)
          return (
            <ThemeProvider theme={themes[defaultTheme]}>
              <IntlProvider locale={langKey} messages={i18nMessages}>
                <React.Fragment>
                  <GlobalStyle />
                  <GlobalStyleUiComponents />
                  <LayoutRoot>
                    <Helmet
                      title={data ? data.site.siteMetadata.title : ''}
                      meta={[
                        {
                          name: 'description',
                          content: data
                            ? data.site.siteMetadata.description
                            : '',
                        },
                        {
                          name: 'keywords',
                          content:
                            'codecontrol, developer, freelance, project, software',
                        },
                      ]}
                    />
                    <LayoutMain>{this.props.children}</LayoutMain>
                    {showNavElements && !hideFooter && <Footer />}
                  </LayoutRoot>
                  {/*<CookieConsent
                    location="bottom"
                    buttonText={i18nMessages['cookieConsent.buttonTitle']}
                    cookieName="cccookieconsent"
                    style={{
                      background: colors.darkBackground,
                      transform: 'translateZ(0)',
                    }}
                    buttonStyle={{
                      background: colors.primary,
                      lineHeight: '25px',
                      fontSize: '0.66rem',
                      color: 'white',
                      textTransform: 'uppercase',
                      fontWeight: 300,
                      letterSpacing: '0.03rem',
                      height: '40px',
                      padding: '0 20px',
                      cursor: 'pointer',
                    }}
                    expires={150}
                  >
                    <FormattedMessage
                      id="cookieConsent.disclaimer"
                      defaultMessage={
                        'This website uses cookies to enhance the user experience'
                      }
                      description="Message shown in the Cookie disclaimer"
                    />
                  </CookieConsent>*/}
                </React.Fragment>
              </IntlProvider>
            </ThemeProvider>
          )
        }}
      />
    )
  }
}

export default IndexLayout
