import * as React from 'react'
import { Link as GatsbyLink, graphql, StaticQuery } from 'gatsby'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n'

const languages = require('../../data/languages')
import { Location, RouterProps } from '@reach/router'
import { media, colors, ArrowListIcon } from 'cc-ui-components'
import styled from 'styled-components'
import ClickOutside from '../common/ClickOutside'

interface LanguageSwitchState {
  open: boolean
}

interface ContentfulWebpage {
  node: {
    node_locale: string
    slug: string
    contentful_id: string
  }
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 46px;
  border: 1px solid ${colors.inverseText};
  border-radius: 4px;
  span {
    color: ${colors.inverseText};
    border-color: ${colors.inverseText};
  }
  ${media.sm`
    height: 56px;
  `};
`

interface MobileButtonProps {
  isOpen?: boolean
}

const Button = styled.div`
  width: 100%;
  color: ${colors.inverseText};
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  justify-content: space-between;
  font-size: 12px;
  line-height: 12px;
  ${(props: MobileButtonProps) =>
    props.isOpen && 'svg { transform: rotate(180deg); }'};
  ${media.sm`
    font-size: 15px;
    line-height: 24px;
  `};
`

const LanguageList = styled.div`
  position: absolute;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  max-height: 170px;
  background-color: ${colors.darkBackground};
  border-bottom: 1px solid ${colors.inverseText};
  margin-top: 10px;
  width: 100%;
  a {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.33px;
    padding: 5px 5px 3px 0;
    color: ${colors.inverseText};
    text-decoration: none;
    &:hover {
      background-color: ${colors.midDarkBackground};
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
`

class LanguageSwitch extends React.PureComponent<
  InjectedIntlProps & RouterProps,
  LanguageSwitchState
> {
  public constructor(props: InjectedIntlProps & RouterProps) {
    super(props)
    this.state = {
      open: false,
    }
  }

  public switchListOpen = () => this.setState({ open: !this.state.open })

  public closeList = () => this.setState({ open: false })

  public render() {
    const { intl } = this.props
    const { open } = this.state

    return (
      <StaticQuery
        query={graphql`
          {
            allContentfulWebpage {
              edges {
                node {
                  node_locale
                  slug
                  contentful_id
                }
              }
            }
            allContentfulBlogPost {
              edges {
                node {
                  node_locale
                  slug
                  contentful_id
                }
              }
            }
          }
        `}
        render={data => {
          const idLangMap = {}
          const idLangMapBlog = {}
          data.allContentfulWebpage.edges.forEach(
            ({ node }: ContentfulWebpage) => {
              if (!idLangMap[node.contentful_id]) {
                idLangMap[node.contentful_id] = {}
              }
              idLangMap[node.contentful_id][node.node_locale.split('-')[0]] =
                node.slug
            }
          )
          data.allContentfulBlogPost.edges.forEach(
            ({ node }: ContentfulWebpage) => {
              if (!idLangMapBlog[node.contentful_id]) {
                idLangMapBlog[node.contentful_id] = {}
              }
              idLangMapBlog[node.contentful_id][
                node.node_locale.split('-')[0]
              ] =
                node.slug
            }
          )

          return (
            <ClickOutside callback={this.closeList}>
              <Wrapper>
                <Location>
                  {({ location }) => {
                    const { langs, defaultLangKey } = languages
                    const langKey = getCurrentLangKey(
                      langs,
                      defaultLangKey,
                      location.pathname
                    )
                    const homeLink = `/${langKey}/`
                    let currentSlug = ''
                    if (location.pathname.includes('/blog/')) {
                      currentSlug = location.pathname
                        .replace(`${homeLink}blog/`, '')
                        .replace('/', '')
                    } else {
                      currentSlug = location.pathname
                        .replace(homeLink, '')
                        .replace('/', '')
                    }
                    let redirectMap = {}
                    Object.values(idLangMap).some((redirects: any) => {
                      if (Object.values(redirects).includes(currentSlug)) {
                        redirectMap = redirects
                        return true
                      }
                      return false
                    })
                    Object.values(idLangMapBlog).some((redirects: any) => {
                      if (Object.values(redirects).includes(currentSlug)) {
                        const newRedirects = {}
                        Object.keys(redirects).forEach((key: string) => {
                          newRedirects[key] = `blog/${redirects[key]}`
                        })
                        redirectMap = newRedirects
                        return true
                      }
                      return false
                    })
                    const langsMenu = getLangs(
                      langs,
                      langKey,
                      getUrlForLang(homeLink, location.pathname)
                    )
                    const currentLanguage = intl.formatMessage({
                      id: `language.${langKey}`,
                    })
                    return (
                      <React.Fragment>
                        <Button onClick={this.switchListOpen} isOpen={open}>
                          {currentLanguage}
                          <ArrowListIcon fill={colors.inverseText} />
                        </Button>
                        {open && (
                          <LanguageList>
                            {langsMenu.map((lang: any, index: number) => {
                              if (lang.langKey === langKey) {
                                return null
                              }
                              const linkTo = redirectMap[lang.langKey]
                                ? `/${lang.langKey}/${
                                    redirectMap[lang.langKey]
                                  }`
                                : lang.link
                              return (
                                <GatsbyLink
                                  key={`language_switch_link_${index}`}
                                  to={linkTo}
                                >
                                  {intl
                                    .formatMessage({
                                      id: `language.${lang.langKey}`,
                                    })
                                    .toUpperCase()}
                                </GatsbyLink>
                              )
                            })}
                          </LanguageList>
                        )}
                      </React.Fragment>
                    )
                  }}
                </Location>
              </Wrapper>
            </ClickOutside>
          )
        }}
      />
    )
  }
}

export default injectIntl(LanguageSwitch)
