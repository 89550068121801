import * as React from 'react'

interface Props {
  children: JSX.Element
  callback: () => void
}

export default class ClickOutside extends React.PureComponent<Props> {
  public wrapperRef: any

  constructor(props: Props) {
    super(props)

    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  public setWrapperRef(node: any) {
    this.wrapperRef = node
  }

  public handleClickOutside(event: MouseEvent | TouchEvent) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.props.callback) {
        this.props.callback()
      }
    }
  }

  public render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>
  }
}
