/**
 * This lib is responsible for CC business logic
 */
import jsonp from 'jsonp'
import { db, serverTimestamp } from '../api'

interface Contact {
  email: string
  name?: string
  phone?: string
  firstName?: string
  lastName?: string
}

function getNames(name: any) {
  if (!!name) {
    const parts = name.trim().split(' ')
    if (parts.length === 1) {
      return { lastName: parts[0] }
    } else {
      return {
        firstName: parts.slice(0, parts.length - 1).join(' '),
        lastName: parts[parts.length - 1],
      }
    }
  } else {
    return { lastName: 'No Name' }
  }
}

function storeLead(contact: Contact, source: any) {
  contact.email = contact.email.toLowerCase()
  contact.phone = contact.phone || ''
  contact = {
    ...contact,
    ...getNames(contact.name),
  }
  submitToPardot(contact)
}

function submitToPardot(contact: Contact) {
  const queryParams = new URLSearchParams()
  queryParams.set('email', contact.email)
  if (contact.firstName) queryParams.set('firstName', contact.firstName)
  if (contact.lastName) queryParams.set('lastName', contact.lastName)
  if (contact.phone) queryParams.set('phone', contact.phone)
  const url = `${
    process.env.GATSBY_PARDOT_FORM_HANDLER_URL
  }?${queryParams.toString()}`
  jsonp(url, { name: 'pardot_form_handler_callback' }, (err, data) => {
    if (err) {
      console.error(err)
    }
    console.log(data)
  })
}

function storeSurvey(responses: any) {
  const survey = {
    ...responses,
    created_at: serverTimestamp(),
  }
  return db.collection('surveys').add(survey)
}

function contactToSource(event: string) {
  return {
    event,
    source: 'contact',
  }
}

function chatToSource(event) {
  return {
    event,
    source: 'chat',
  }
}

function surveyToSource(surveyId: string, event: string) {
  return {
    event,
    source: 'survey',
    id: surveyId,
  }
}

export { storeLead, storeSurvey, chatToSource, surveyToSource, contactToSource }
