import * as React from 'react'
import styled from 'styled-components'

import { media, colors, NextSliderArrowIcon } from 'cc-ui-components'
//import * as SegmentAnalytics from '../../lib/segment'
import * as fetchJsonp from 'fetch-jsonp'
import { PulseLoader } from 'react-spinners'

const Form = styled.form`
  display: flex;
  height: 46px;
  ${media.sm`
    height: 56px;
  `};
`

const SubmitButton = styled.button`
  outline: none;
  border: 1px solid ${colors.inverseText};
  border-left: none;
  background: none;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`

const SubmitButtonArrow = styled(NextSliderArrowIcon)`
  position: relative;
`

const EmailInput = styled.input`
  display: inline-flex;
  flex: 1;
  font-size: 12px;
  line-height: 12px;
  background: none;
  outline: none;
  border: 1px solid ${colors.inverseText};
  color: ${colors.inverseText};
  &::placeholder {
    color: ${colors.inverseText};
  }
  padding: 16px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
  font-weight: 400;
  ${media.sm`
    font-size: 15px;
    line-height: 24px;
  `};
`

const SuccessText = styled.div`
  font-size: 10px;
  text-align: center;
  margin: 1rem 0;
  color: ${colors.success};
`
const ErrorText = styled.div`
  font-size: 10px;
  text-align: center;
  margin: 1rem 0;
  color: ${colors.failure};
  a {
    color: ${colors.failure};
    text-decoration: underline;
  }
`

interface NewsletterState {
  progress: boolean
  success: null
  error: null
}

export class NewsletterRegistration extends React.Component<
  any,
  NewsletterState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      progress: false,
      success: null,
      error: null,
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Form
          action={`https://codecontrol.us12.list-manage.com/subscribe/post?u=5ae80c96e5213891fb82fc0e2&id=${
            process.env.GATSBY_MAILCHIMP_LIST_ID
          }`}
          onSubmit={this.onSubmit}
        >
          <EmailInput
            type="email"
            name="EMAIL"
            placeholder="Email Address..."
          />
          <SubmitButton>
            {(!this.state.progress || this.state.success) && (
              <SubmitButtonArrow fill={colors.inverseText} />
            )}
            {this.state.progress && (
              <PulseLoader size={7} sizeUnit={'px'} color={'white'} />
            )}
          </SubmitButton>
        </Form>
        {this.state.success && <SuccessText>{this.state.success}</SuccessText>}
        {this.state.error && (
          <ErrorText
            dangerouslySetInnerHTML={{ __html: this.state.error || '' }}
          />
        )}
      </React.Fragment>
    )
  }

  private onSubmit = (event: any) => {
    event.preventDefault()
    this.setState(() => ({ progress: true, success: null, error: null }))
    const data = new FormData(event.target)
    const email = String(data.get('EMAIL')).toLowerCase()
    const targetUrl = `${event.target.action.replace(
      '/post?u=',
      '/post-json?u='
    )}&EMAIL=${email}`
    //SegmentAnalytics.alias(email)
    //SegmentAnalytics.identify(email, { email })
    fetchJsonp(targetUrl, { jsonpCallback: 'c' })
      .then(response => {
        return response.json()
      })
      .then(json => {
        if (json.result === 'error') {
          this.setState(() => ({
            progress: false,
            success: null,
            error: json.msg,
          }))
        } else {
          this.setState(() => ({
            progress: false,
            success: json.msg,
            error: null,
          }))
          //SegmentAnalytics.trackEvent('Send Newsletter Registration')
        }
      })
      .catch(ex => {
        console.log('parsing failed', ex)
      })
  }
}

export default NewsletterRegistration
