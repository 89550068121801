import * as firebase from 'firebase/app'
import 'firebase/firestore'

try {
  firebase.initializeApp({
    apiKey: process.env.GATSBY_FIREBASE_CONFIG_KEY,
    authDomain: `${
      process.env.GATSBY_FIREBASE_CONFIG_PROJECT_ID
    }.firebaseapp.com`,
    projectId: process.env.GATSBY_FIREBASE_CONFIG_PROJECT_ID,
  })
} catch (error) {
  // we skip the already exists error message, which
  // is not actually an error when hot-reloading
  if (!/already exists/.test(error.message)) {
    throw error
  }
}

const db = firebase.firestore()
db.settings({ timestampsInSnapshots: true })
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp

export { db, serverTimestamp }
