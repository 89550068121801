import { colors } from 'cc-ui-components'

export const ROOT_FONT_SIZE = 15
export const ROOT_LINE_HEIGHT = 22
export const ROOT_LETTER_SPACING = 0.38

export const config = {
  rootFont: '"Montserrat", Helvetica, Arial, sans-serif',
  rootFontSize: `${ROOT_FONT_SIZE}px`,
  rootLineHeight: ROOT_LINE_HEIGHT / ROOT_FONT_SIZE,
  rootLetterSpacing: ROOT_LETTER_SPACING
}

export const subtypesValueMap: { [key: string]: string } = {
  backendDeveloper: 'Backend Developer',
  frontendDeveloper: 'Front-End Developer',
  fullstackDeveloper: 'Full-Stack Developer',
  mobileDeveloper: 'Mobile Developer',
  dataScientist: 'Data Scientist',
  machineLearningAI: 'Machine Learning / AI',
  vr: 'VR',
  devOps: 'DevOps',
  architect: 'Architect',
  cto: 'CTO',
  qa: 'QA',
  ui: 'UI',
  ux: 'UX',
  uiux: 'UI / UX',
  printgraphic: 'Print / Graphic',
  pm: 'Project Manager'
}

export interface PageTheme {
  backgroundColor: string
  textColor: string
  name?: string
}

export const calendlyLanguageLinks: { [key: string]: string | undefined } = {
  default: process.env.GATSBY_CALENDLY_DEFAULT_LINK || 'https://calendly.com/cc-business-development/15-minutes-introduction-call',
  en: process.env.GATSBY_CALENDLY_EN_LINK,
  de: process.env.GATSBY_CALENDLY_DE_LINK,
  fr: process.env.GATSBY_CALENDLY_FR_LINK,
  es: process.env.GATSBY_CALENDLY_ES_LINK,
  pt: process.env.GATSBY_CALENDLY_PT_LINK
}

export const bgColorMapping: { [key: string]: string } = {
  regular: colors.bodyBackground,
  light: colors.lightBackground,
  dark: colors.darkBackground
}
