import * as React from 'react'

export const TelephoneIcon = ({ viewBox = '0 0 100 100' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox={viewBox}
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
  >
    <path d="m64.871 94.998c4.166-.004 8.089-1.625 11.049-4.569 10.497-10.494 2.729-18.643-1.006-22.56-3.346-3.504-8.07-5.861-10.385-5.863-2.962 0-4.926 1.305-6.659 2.455-1.405.933-2.515 1.67-3.976 1.67-2.691 0-6.62-2.623-12.012-8.018-10.193-10.192-8.495-12.75-6.346-15.988 1.15-1.733 2.453-3.697 2.453-6.659 0-2.315-2.356-7.04-5.863-10.382-3.443-3.284-7.272-6.324-11.933-6.324-3.574 0-7.05 1.739-10.63 5.319-2.942 2.956-4.563 6.88-4.563 11.049 0 19.022 40.848 59.868 59.871 59.87zm-53.054-68.669c2.95-2.95 5.69-4.384 8.376-4.384 3.717 0 6.974 2.81 9.735 5.444 3.376 3.219 4.876 7.133 4.876 8.078 0 2.001-.891 3.344-1.923 4.898-3.094 4.666-4.423 8.831 6.748 20.002 6.188 6.188 10.587 8.947 14.265 8.947 2.421 0 4.183-1.169 5.736-2.199 1.555-1.033 2.897-1.923 4.896-1.923.944 0 4.859 1.502 8.083 4.878 4.931 5.171 8.722 10.445 1.06 18.105-2.355 2.345-5.48 3.636-8.799 3.638-7.867 0-21.542-8.609-34.835-21.932-13.273-13.303-21.849-26.944-21.85-34.753.001-3.321 1.291-6.447 3.632-8.799z" />
    <path d="m37.415 15.315c13.251-10.41 32.531-9.352 44.572 2.691 12.083 12.083 13.113 31.402 2.624 44.662-.547.689-.48 1.692.184 2.271.662.577 1.672.51 2.22-.177 11.582-14.521 10.486-35.746-2.774-49.007-13.219-13.218-34.401-14.345-48.911-2.85-.689.546-.761 1.555-.185 2.22s1.579.734 2.27.19z" />
    <path d="m72.286 52.389c.703.528 1.708.39 2.178-.353 1.93-3.058 2.941-6.562 2.941-10.227 0-.793-.049-1.592-.145-2.371-1.183-9.606-9.383-16.849-19.075-16.849-3.642 0-7.126 1.001-10.171 2.909-.746.467-.887 1.471-.361 2.175.526.705 1.52.842 2.273.388 2.486-1.5 5.31-2.287 8.259-2.288 8.087 0 14.928 6.042 15.913 14.055.081.651.122 1.317.122 1.98 0 2.968-.795 5.809-2.313 8.305-.457.752-.323 1.747.379 2.276z" />
  </svg>
)
