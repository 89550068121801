module.exports = {
  'global.full_name': 'Name',
  'global.email': 'Business Email',
  'global.phone': 'Phone number',
  'global.privacy': 'Privacy',
  'global.imprint': 'Imprint',
  'global.get-in-contact': 'Get in Contact',
  'global.terms': 'Terms of Service',
  'path.contact': '/en/contact',
  'path.imprint': '/en/imprint',
  'path.privacy': '/en/privacy',
  'path.terms': '/en/terms-of-service',
  'path.thank-you': '/en/thank-you',
  'path.get-in-touch': '/en/get-in-touch',
  'cookieConsent.disclaimer':
    'This website uses cookies to enhance the user experience.',
  'cookieConsent.buttonTitle': 'Accept Cookies',
  selectLanguage: 'Select your languages',
  'language.de': 'German',
  'language.en': 'English',
  'language.fr': 'French',
  'header.cta': 'Hire Now',
  'footer.about':
    'CodeControl manages 750+ trusted freelance developers, who create top-notch software for clients like Lufthansa, BCG, and mymuesli.',
  'footer.cta': 'LEARN MORE',
  'footer.newsletter_description':
    'Want to stay in the loop about our latest news, events, & blogs?',
  'module.popular_searches.headline': 'Popular developer searches',
  'blog.index.headline': 'CodeControl Blog',
  'blog.index.subline': 'A singular look on tech & the future of work',
  'blog.index.mobile_more': 'Load more...',
  'blog.index.medium_cta.headline': 'Our latest food for thought',
  'blog.index.medium_cta.subline': 'Future of Work publication',
  'blog.index.medium_cta.button': 'Go to Medium',
  'blog.index.editor_section.headline': 'Join our publication on Medium.',
  'blog.index.editor_section.subline': 'Future of Work Publication',
  'blog.index.editor_section.copy':
    'Are you also fascinated by how work will look like in the future, and do you write in-depth articles on the topic? If so, send us an email with writing samples, and see your work featured in our ‘Future of Work’ publication.',
  'blog.article.author_section.headline': 'About the author',
  'blog.index.editor_section.cta': 'Email us',
  'blog.module.newsletter_section.headline':
    'Want to join our newsletter?',
  'blog.module.newsletter_section.cta': 'Sign me up',
  'talent_cv.years_of_experience': 'Years professional experience',
  'talent_cv.cta': 'Hire Now',
  'talent_cv.ct_available': 'Hire',
  'talent_cv.ct_not_available': 'Find talent',
  'talent_cv.part_time_available_text':
    'is currently available for part-time hire.',
  'talent_cv.full_time_available_text':
    'is currently available for full-time hire.',
  'talent_cv.not_available_text': 'is currently not available for hire.',
  'talent_ct.work_experience.present': 'present',
  'still.havent.title': "STILL HAVEN'T FOUND WHAT YOU WERE AFTER?",
  'still.havent.text':
    "Then please send us a message. We're very happy to answer any question you may have!",
  'still.havent.cta': 'GET IN TOUCH',
  'talent_cv.skill_section.headline': 'Key Skills',
  'talent_cv.skill_section.techskills': 'Technical Skills',
  'talent_cv.skill_section.yearsofxp': 'Years of experience',
  'talent_cv.skill_section.proficiency': 'Proficiency',
  'talent_cv.project_section.headline': 'Work experience',
  'talent_cv.rating_section.headline': 'Ratings & References',
  'talent_cv.education_section.headline': 'Education & Certification',
  'talent_cv.language_section.headline': 'Languages',
  'hire.title': 'Work | with CodeControl',
  'contact.title': 'Talk | to CodeControl',
  'contact.headline': 'Want to get in touch? Drop your details below.',
  'contact.calendly_section.headline': 'Jump on a quick call.',
  'contact.calendly_section.subline': 'Please choose a time that suits you.',
  'contact.email_section.headline': 'Prefer email? Drop your details below.',
  'contact.email_section.CTA': 'Contact me',
  'contact.email_section.footer':
    'By submitting you agree to the Privacy Policy.',
  'thank_you.headline': 'Thank you!',
  'thank_you.intro_line1':
    'Someone from CodeControl crew will email you shortly.',
  'thank_you.intro_line2':
    "Can't wait? The feel free to email us at: hello@codecontrol.io",
  'thank_you.intro_line3': "While you're here, why not have a read!",
  'thank_you.social_section.headline': 'Or follow us for news & events! ',
  'get-in-touch.requiredName': 'Please input your full name',
  'get-in-touch.validEmail': 'Please input a valid email',
  'get-in-touch.requiredEmail': 'Please input your email',
  'get-in-touch.requiredPhone': 'Please input your phone number',
  'survey.ctaButton': 'Start',
  'survey.reset': 'Reset',
  'survey.progress': 'Question',
  'survey.previousButton': 'Previous',
  'survey.contactInfo':
    'Congratulations, we have found the perfect talent for you. Register now for a free quote.',
  'survey.placeholderName': 'Name',
  'survey.placeholderEmail': 'Email address',
  'survey.placeholderCompany': 'Company name (Optional)',
  'survey.continueButton': 'Continue',
  'survey.outroTitle': 'Thank you',
  'survey.outroDescriptionOne':
    'Someone from CodeControl will email you shortly.',
  'survey.outroDescriptionTwo': "Can't wait? Then feel free to email us at",
  'survey.outroSubtitle': 'Or follow us for news and events!',
  'landingpage.contactform.email': 'Business Email',
  'landingpage.contactform.name': 'Name',
  'landingpage.contactform.phone': 'Phone Number',
  'landingpage.contactform.about': 'More about your project',
  'landingpage.contactform.submit': 'Send',
  'landingpage.contactform.error.validEmail': 'Please input a valid email',
  'landingpage.contactform.error.requiredEmail': 'Please input an email',
  'landingpage.contactform.error.requiredPhone': 'Please input a phone number',
  'landingpage.contactform.error.requiredName': 'Please input your full name',
  'landingpage.contactform.sentTitle': 'We have sent your message!',
  'landingpage.contactform.sentSubheading':
    'We will get back to you on {email} or by phone.',
  'developers.meta.description':
    'Are you looking for a talented developer for your project? Find the right talent from the top freelancers with CodeControl.',
  'developers.cta.getInTouch': 'GET IN TOUCH',
  'developers.cta.getAQuote': 'GET A QUOTE',
  'developers.teaser1.title': 'Hire top {prefix} {skill} {jobTitlePlural}.',
  'developers.teaser1.subtitle': 'Discover top talent at your service',
  'developers.teaser1.copy': 'Hiring freelancers has never been so easy',
  'developers.teaser1.linkSubtext':
    '<p>Call us for a free consultation:</p>\n<p><a href="tel:+4903030808013" target="_blank" rel="nofollow"><strong>+49 (0) 30 30808013</strong></a></p>',
  'developers.teaser2.title':
    'Top {skill} {jobTitlePlural} ready to work for you',
  'developers.grid.title': '{skill} {jobTitleSingular}',
  'developers.grid.titleSenior': 'Senior {skill} {jobTitleSingular}',
  'developers.timeline1.step1.title':
    '30+ professional {skill} {jobTitlePlural}',
  'developers.timeline1.step2.title': 'Ready-to-start within 3 days',
  'developers.timeline1.step3.title':
    'Successfully worked for well-known start-ups & tech companies',
  'developers.teaser3.title': 'YOU WILL BE IN GOOD COMPANY',
  'developers.teaser3.copy':
    '<blockquote>\n<p>“We are working with CodeControl on innovation projects. It is truly refreshing! CodeControl’s speed, flexibility, and the quality of their work is outstanding. We highly recommend them for any digital project.” </p>\n</blockquote>\n<blockquote>\n<p>Rafija Karadza – E-Commerce Specialist\n<strong>DM DROGERIE MARKT ÖSTERREICH</strong></p>\n</blockquote>',
  'developers.teaser4.title':
    'Why you’ll want to hire {skill} {jobTitlePlural} from CodeControl',
  'developers.teaser4.subtitle': 'The Future of Work',
  'developers.teaser5.title':
    'Hire from the top {skill} {jobTitlePlural}',
  'developers.teaser5.copy':
    '<p>At CodeControl, we only hire the best talent who made it through our rigorous screening process. Working with us, you’ll get access to the community of top tech talents.</p><p>No matter what you’re working on, we’ll provide you with a developer with a wide experience relevant to the role you need to fill.</p>',
  'developers.teaser6.title':
    'Find the best {skill} {jobTitlePlural} within 48 hours',
  'developers.teaser6.copy':
    '<p>The speed of a hiring process becomes a blocker for operations for more and more companies. With our freelance developers, your company will operate fully and meet market needs.</p>\n<p>Instead of spending up to 42 days on headhunting, you’ll have an ideal candidate ready to work with you within 48 hours.</p>',
  'developers.teaser7.title': 'Avoid the painstaking assessment process',
  'developers.teaser7.copy':
    '<p>You don’t need to spend your time assessing developers’ coding skills anymore. Our freelance developers have already made it through a three-stage assessment procedure.</p>',
  'developers.teaser8.title': 'Reduce risks',
  'developers.teaser8.copy':
    '<p>Working with CodeControl, you can always be sure the project will be delivered. After matching you with the right candidate, we keep monitoring the freelancer’s performance to ensure you’re satisfied.</p>',
  'developers.teaser9.title': 'How to find top-notch talent in a flash.',
  'developers.teaser9.subtitle': 'HOW IT WORKS',
  'developers.timeline2.step1.title': 'Understanding your needs.',
  'developers.timeline2.step1.description':
    '<p>Start by dropping us a message. Then jump on a call with one of the team to clarify what type of talent you require.</p>',
  'developers.timeline2.step2.title': 'Matching you with top talent.',
  'developers.timeline2.step2.description':
    '<p>Within two days, we will send you the profile of an ideal candidate or start assembling a project team for you.</p>',
  'developers.timeline2.step3.title': 'Getting down to business.',
  'developers.timeline2.step3.description':
    '<p>Jump on a call with your candidate - if you’re happy you can start working together immediately! We will handle all the admin.</p>',
  'developers.timeline2.step4.title': 'Monitoring performance.',
  'developers.timeline2.step4.description':
    '<p>We continue to monitor the freelancer’s performance and ensure you’re satisfied. If not, we find you a replacement.</p>',
  'developers.faq.title': 'What would you like to know?',
  'developers.faq.subtitle': 'FREQUENTLY ASKED QUESTIONS',
  'developers.faq.q1.title': 'How does it work?',
  'developers.faq.q1.answer':
    '<p>Start by getting in touch with us (click the button below!) and then jump on a quick call with one of the team. Once we have an understanding of your needs, we send over the profile of an ideal candidate and set up a call so you can get to know them better. If you’re both satisfied, start working together immediately - while we handle the contractual side of things.</p>',
  'developers.faq.q2.title': 'How quickly can someone start?',
  'developers.faq.q2.answer':
    '<p>Once we know what you need, we’ll scan our database to find a freelancer with the right skillset and availability. Within two days, you’ll have the profile of our top recommendation and you can start working together straight away. If you would like to see more options, we can send those your way as well.</p>',
  'developers.faq.q3.title': 'How does the pricing & payment work?',
  'developers.faq.q3.answer':
    '<p>Pricing generally depends on the specific skill-set (and spoken language) that you require. Reach out to us to scope the project and get an estimate. In terms of payment, we invoice at the end of every month based on the number of hours worked by your freelancer.</p>',
  'developers.faq.q4.title': 'What happens if something goes wrong?',
  'developers.faq.q4.answer':
    '<p>We continue to monitor the freelancer’s performance and ensure you’re satisfied. If for whatever reason you’re not, we will find you a replacement in a flash!</p>',
  'developers.teaser10.title': 'Get in touch with us for a quotation.',
  'developers.teaser10.subtitle': 'HOW MUCH DOES A DEVELOPER COST.',
  'developers.contact.title': 'Got a project? Let’s talk!',
  'projects.meta.description':
    'Have CodeControl build a project team for you. Contact us and we will assemble your custom team with developers, designers, and product managers in as little as 5 days.',
  'projects.cta.getInTouch': 'Start a project',
  'projects.cta.startHiring': 'Start Hiring',
  'projects.cta.getAQuote': 'Get a quote',
  'projects.cta.scheduleCall': 'Schedule Call',
  'projects.teaser1.title': 'Relax. Your {skill} project is in good hands.',
  'projects.teaser1.subtitle': '',
  'projects.teaser1.copy':
    'Have us assemble {article} {skill} {jobTitleSingular} team for you.',
  'projects.teaser1.linkSubtext':
    '<p>Call us for a free consultation:</p>\n<p><a href="tel:+4903030808013" target="_blank" rel="nofollow"><strong>+49 (0) 30 30808013</strong></a></p>',
  'projects.teaser2.title':
    'Customized {skill} teams ready to tackle your project.',
  'projects.teaser2.subtitle': 'Trusted, experienced talent.',
  'projects.timeline1.step1.title': 'PURPOSE BUILT TEAMS.',
  'projects.timeline1.step1.description':
    'We assemble a custom team with top developers, designers and product managers to fit your needs.',
  'projects.timeline1.step2.title': 'FAST PROJECT STAFFING.',
  'projects.timeline1.step2.description':
    'Quickly staffed projects, your team of freelancers are ready to start work in as little as 5 days.',
  'projects.timeline1.step3.title': 'FULL PROJECT SETUP.',
  'projects.timeline1.step3.description':
    'We support in defining your exact needs - and ensuring they are met - from start to finish.',
  'projects.grid.title': '{skill} {jobTitleSingular}',
  'projects.grid.titleSenior': 'Senior {skill} {jobTitleSingular}',
  'projects.teaser3.title': 'What are your goals, budget and timeline?',
  'projects.teaser3.subtitle': 'We get it done.',
  'projects.teaser3.copy':
    '<p>Depending on the product you want to build, your timeline, budget, and current team structure, we will put together a team that fits seamlessly into your company.</p><p>Full support in creating unmatched products.</p>',
  'projects.teaser4.title': 'YOU WILL BE IN GOOD COMPANY',
  'projects.teaser4.subtitle': 'Leaders create with codecontrol',
  'projects.teaser5.title': 'Our process with project teams.',
  'projects.teaser5.subtitle': 'SUPPORT AT EVERY STAGE.',
  'projects.slider1.slide1.title': 'Project Scoping',
  'projects.slider1.slide1.subtitle': 'Understanding your needs.',
  'projects.slider1.slide1.copy':
    '<p>Requirements gathering doesn’t have to be a bore. Get started with one of the team to clarify your project vision and goals.</p>\n<p>Aims of this stage:</p>\n<ul>\n<li>Determine the skills required</li>\n<li>Establish the timeframe</li>\n<li>Set the goals</li>\n</ul>',
  'projects.slider1.slide2.title': 'Kickoff',
  'projects.slider1.slide2.subtitle': 'Launching the Project.',
  'projects.slider1.slide2.copy':
    '<p>We start assembling your project team within 48h, including designers, developers &#x26; PMs as needed. Happy with the team? Then start working!\nAims of this stage:</p>\n<ul>\n<li>Begin with a workshop</li>\n<li>Design the approach</li>\n<li>Staff the team</li>\n<li>Involve key stakeholders</li>\n</ul>',
  'projects.slider1.slide3.title': 'Agile Sprints',
  'projects.slider1.slide3.subtitle': 'Implementing & iterating.',
  'projects.slider1.slide3.copy':
    '<p>Watch your vision come to life as the project team delivers the goods in fortnightly sprints. Our agile approach ensures they stick to time and budget.</p>\n<p>Aims of this stage:</p>\n<ul>\n<li>Design the experience</li>\n<li>Commit code</li>\n<li>Test with users</li>\n<li>Repeat</li>\n</ul>',
  'projects.slider1.slide4.title': 'Project Delivery',
  'projects.slider1.slide4.subtitle': 'Taking it live.',
  'projects.slider1.slide4.copy':
    '<p>It’s time to take your product live, and we’re with you every step of the way. Our project team is on board to support you until it’s just right.</p>\n<p>Aims of this stage:</p>\n<ul>\n<li>Go to market</li>\n<li>Monitor usage</li>\n<li>Collect user feedback</li>\n<li>Plan for growth</li>\n</ul>',
  'projects.teaser6.title': 'Nice stuff people said about us.',
  'projects.teaser6.subtitle': 'Quote, unquote',
  'projects.slider2.slide1.name': 'Lufthansa Innovation Hub',
  'projects.slider2.slide1.quote':
    '<p>Finally someone where you have full control and transparency, while the software is built lean and scalable. A dream come true!</p>',
  'projects.slider2.slide1.quoteGiver':
    'Marc Schmöger | Senior Product Manager',
  'projects.slider2.slide2.name': 'Coya AG',
  'projects.slider2.slide2.quote':
    '<p>We needed support with backend development, so CodeControl matched us with Fernando, a senior DevOps engineer, and Aleksandar, an Elm developer. Both were talented, able to start work quickly, and had great communication skills.</p>',
  'projects.slider2.slide2.quoteGiver': 'Andrew Shaw | CEO',
  'projects.slider2.slide3.name': 'Siemens Mobility',
  'projects.slider2.slide3.quote':
    '<p>CodeControl has been helping us to develop customer applications on Siemens IoT platform MindSphere. With their highly competent and dedicated teams, they proved to be a trusted partner for digital innovation projects.</p>',
  'projects.slider2.slide3.quoteGiver':
    'R.D. - Global Product Manager Connectivity & IoT',
  'projects.slider2.slide4.name': 'DM DROGERIE MARKT ÖSTERREICH',
  'projects.slider2.slide4.quote':
    '<p>We are working with CodeControl on innovation projects. It is truly refreshing! CodeControl’s speed, flexibility, and the quality of their work is outstanding. We highly recommend them for any digital project.</p>',
  'projects.slider2.slide4.quoteGiver':
    'Rafija Karadza – E-Commerce Specialist',
  'projects.teaser7.title': 'What type of product are you building?',
  'projects.teaser7.subtitle': 'Our product solutions',
  'projects.teaser7.copy':
    '<p>Our freelancers have experience across numerous fields of expertise, so no matter what you’re working on, we can assemble the optimal team for you.\nSimply start by telling us what you’re building.</p>',
  'projects.teaser8.title': 'Get in touch with us for a quotation.',
  'projects.teaser8.subtitle': 'How much does a team cost.',
  'projects.faq.title': 'What would you like to know?',
  'projects.faq.subtitle': 'FREQUENTLY ASKED QUESTIONS',
  'projects.faq.q1.title': 'How does it work?',
  'projects.faq.q1.answer':
    'Start by getting in touch with us (click the button below!) and then jump on a quick call with one of the team. Once we have an understanding of your needs, we send over the profiles of the ideal team candidates and set up a call so you can get to know them better. If you’re all satisfied, start working together immediately - while we handle the contractual side of things.',
  'projects.faq.q2.title': 'How quickly can you start?',
  'projects.faq.q2.answer':
    'Once we know what you need, we’ll scan our database to find freelancers with the right skillset and availability. Within two days, you’ll have the profiles of our top recommendations for the project and you can start working together straight away. If you would like to see more options, we can send those your way as well.',
  'projects.faq.q3.title': 'How does the pricing & payment work?',
  'projects.faq.q3.answer':
    'Pricing generally depends on the specific skill-sets (and spoken language) that you require. Reach out to us to scope the project and get an estimate. In terms of payment, we invoice at the end of every month based on the number of hours worked by your project team.',
  'projects.faq.q4.title': 'What happens if something goes wrong?',
  'projects.faq.q4.answer':
    'We continue to monitor the project team’s performance and ensure you’re satisfied. If for whatever reason you’re not, we will find you a replacement in a flash!',
  'projects.contact.title': 'Got a project? Let’s talk!',
  'clients.project.details': 'Details'
}
