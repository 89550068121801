import { calendlyLanguageLinks, ROOT_FONT_SIZE } from './config'

export function getRemValue(value: number): string {
  return `${value / ROOT_FONT_SIZE}rem`
}

export const changeNavColor = (name: string) => {
  setTimeout(() => {
    const colorName = name || 'white'
    const navWrapper = document.getElementById('nav-wrapper')
    if (!navWrapper) {
      return
    }
    const newClassName = `${navWrapper.className
      .replace(' white', '')
      .replace(' black', '')
      .replace(' light', '')} ${colorName}`

    if (navWrapper.className !== newClassName) {
      navWrapper.className = newClassName
    }
  }, 0)
}

export const getLanguageCode = () => (typeof window !== 'undefined' ? window.location.href.split('/')[3] : 'en')

export const getCalendlyLink = (language: string) => calendlyLanguageLinks[language] || calendlyLanguageLinks.default
