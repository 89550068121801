import { withPrefix } from 'gatsby-link'

export const logos = {
  accenture: withPrefix('/logos/accenture.svg'),
  airbnb: withPrefix('/logos/airbnb.svg'),
  calendly: withPrefix('/logos/calendly.png'),
  careship: withPrefix('/logos/careship.png'),
  coya: withPrefix('/logos/coya.png'),
  dm: withPrefix('/logos/dm.svg'),
  lufthansa: withPrefix('/logos/lufthansa.svg'),
  lufthansaHub: withPrefix('/logos/lufthansa-hub.png'),
  mymuesli: withPrefix('/logos/mymuesli.svg'),
  orderBird: withPrefix('/logos/order-bird.svg'),
  siemens: withPrefix('/logos/siemens.svg'),
  universal: withPrefix('/logos/universal.svg'),
}
