import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface LayoutMainProps {
  children: string | JSX.Element | JSX.Element[]
}

const LayoutMain: React.SFC<LayoutMainProps> = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
)

export default LayoutMain
