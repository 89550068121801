import styledNormalize from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import { media, colors, onEvent } from 'cc-ui-components'
import { config } from './config'

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  html {
    box-sizing: border-box;
    
    div:focus {
      outline: none !important;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    font-size: ${config.rootFontSize};
    line-height: ${config.rootLineHeight};
    letter-spacing: ${config.rootLetterSpacing};
  }

  body {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: ${config.rootFont};
    color: ${colors.text};
    background-color: ${colors.bodyBackground};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  // Set defaults for links
  a {
    color: ${colors.primary};
    text-decoration: none;

    ${onEvent`
      text-decoration: underline;
    `}
  }
  
  ${media.print`
    html {
      font-size: 11.5px;
    }
    #cv-page {
      * {
        -webkit-print-color-adjust: exact;
      }
      #cv-page-content {
        & > div {
          padding: 0;
          position: relative;
          display: block;
          & > div {
            position: relative;
            page-break-inside: avoid;
          }
        }
      }
      #cv-page-basic-info {
        margin-top: 0;
      }
      #cv-page-skills {
        margin-top: 30px;
      }
      a {
        div {
          display: none;
        }
      }
    }
    header,
    footer,
    #popular-searches-section {
      display: none !important;
    }
  `}
  
  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }
`
